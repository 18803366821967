<template>
  <v-container>
    <!-- <v-tabs v-model="tabClassGroups" class="overflow-hidden">
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        class="pa-0"
        v-for="(classGroup, tabIndex) in lesson.classGroups"
        :href="'#tabClassGroups-' + (tabIndex + 1)"
        :key="'tabClassGroups' + tabIndex"
      >
        {{ classGroup.departmentName }}{{ classGroup.classGroupName }}
      </v-tab>
    </v-tabs> -->
    <div class="d-flex flex-row">
      <div class="px-4">
        <template v-if="lesson.classGroups">
          <span
            @click="
              filterStudentsByClassGroup = classGroup.classGroupRef;
              $store.state.selectedClassGroupTab = cgindex;
            "
            style="cursor: pointer"
            class="mr-4"
            v-for="(classGroup, cgindex) in lesson.classGroups"
            :key="cgindex"
          >
            <span
              v-if="
                $store.state.selectedClassGroupTab == cgindex
              "
              class="font-weight-bold pb-2 px-10"
              style="color: #56ca28 !important; border-bottom: 3px solid"
              >{{ classGroup.departmentName + classGroup.classGroupName }}
            </span>
            <span v-else
              >{{ classGroup.departmentName + classGroup.classGroupName }}
            </span>
          </span></template
        >
        <!-- <span
          v-if="students && lesson.isGroupBased"
          @click="filterStudentsByClassGroup = -1"
        >
          <span
            v-if="filterStudentsByClassGroup == -1"
            class="font-weight-bold pb-2 px-10"
            style="
              cursor: pointer;
              color: #56ca28 !important;
              border-bottom: 3px solid;
            "
            >Сонгосон</span
          >
          <span v-else style="cursor: pointer">Сонгосон</span>
        </span> -->
        <span
          v-if="students && lesson.isGroupBased"
          @click="
            $store.state.selectedClassGroupTab = lesson.classGroups.length;
            filterStudentsByClassGroup = -1;
          "
        >
          <span
            v-if="filterStudentsByClassGroup == -1"
            class="font-weight-bold py-2 px-4"
            style="
              cursor: pointer;
              color: #56ca28 !important;
              border-bottom: 3px solid;
            "
            >Групп <span v-if="students">({{ students.length }})</span></span
          >
          <span v-else style="cursor: pointer; line-height: 2" class="py-2 px-4"
            >Групп
            <span v-if="students">({{ students.length }})</span>
          </span>
        </span>
      </div>
    </div>

    <StudentsHomeWorkScoreTable
      v-if="filterStudentsByClassGroup"
      :students="students3"
      :lesson="lesson"
      :homeworks="homeworks"
    ></StudentsHomeWorkScoreTable>
  </v-container>
</template>
<script>
import StudentsHomeWorkScoreTable from "./StudentsHomeWorkScoreTable.vue";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      filterStudentsByClassGroup: null,
      tabClassGroups: null,
    };
  },
  computed: {
    ...sync("*"),
    // students3() {
    //   var list = [];
    //   if (this.filterStudentsByClassGroup) {
    //     if (this.lesson.isGroupBased) {
    //       for (var student of this.students) {
    //         list.push(student);
    //       }
    //     } else {
    //       for (var stud of this.students2) {
    //         if (
    //           stud["classGroup-"+this.userData.school.currentYear].path ==
    //           this.filterStudentsByClassGroup.path
    //         ) {
    //           list.push(stud);
    //         }
    //       }
    //     }
    //   }
    //   return list;
    // },
  },
  components: {
    StudentsHomeWorkScoreTable,
  },
  mounted() {
    // console.log(this.students)
    // console.log(this.students2)
    if (this.lesson.classGroups && this.lesson.classGroups.length > 0) {
      this.filterStudentsByClassGroup =
        this.lesson.classGroups[0].classGroupRef;
    } else this.filterStudentsByClassGroup = -1;
  },
  props: {
    lesson: {
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
    students2: {
      type: Array,
      required: true,
    },
    students3: {
      type: Array,
      required: true,
    },
    homeworks: {
      type: Array,
      required: true,
    },
  },
};
</script>
