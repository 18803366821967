<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      {{ actionstatus }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-text class="px-0 py-0 scoreTables mt-4" v-if="loading == false">
      <v-data-table
        :headers="headers2"
        :items="students"
        :items-per-page="-1"
        :search="search"
        hide-details
        hide-default-footer
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td style="width: 1%">{{ props.item.index }}</td>
            <td>
              <span class="font-weight-bold"> {{ props.item.firstName }}</span>

              <span v-if="props.item.lastName"
                >, {{ props.item.lastName }}</span
              >
            </td>
            <td
              v-for="(homework, hindex) in homeworks"
              :key="'homework-' + hindex"
            >
              <input
                :id="props.item.id + hindex"
                :placeholder="0"
                v-if="props.item.assignments[homework.id]"
                type="number"
                v-model="props.item.assignments[homework.id].score"
                @change="
                  updateHomeworkScore(props.item.assignments[homework.id]);
                "
                style="width: 60px"
              />
              <input
                :id="props.item.id + hindex"
                :placeholder="0"
                v-else
                type="number"
                v-model="props.item.assignments[homework.id]"
                @change="
                  createHomeworkScore(props.item, homework);
                "
                style="width: 60px"
              />
              <!-- <small
                v-if="
                  props.item.saved.find(
                    (assignment) => assignment == homework.id
                  )
                "
                >Амжилттай xадгалагдлаа.</small
              > -->
            </td>
            <td style="background-color: #ffebee">
              {{ (props.item.totalScore / homeworks.length).toFixed(1) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-container>
</template>
<script>
export default {
  name: "light-table-transparent",
  data() {
    return {
      actionstatus: null,
      search: "",
      snackbar: false,
      headers2: [
        {
          text: "No",
          align: "start",
          value: "index",
          sortable: true,
        },
        {
          text: "Нэр",
          align: "start",
          value: "firstName",
          sortable: true,
        },
      ],
      loading: true,
    };
  },
  computed: {},
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
    homeworks: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateHomeworkScore(homework) {
      homework.ref.update({ score: homework.score });
    },
    createHomeworkScore(student, homework) {
      homework.ref.collection("assignments").doc(student.id).set(
        {
          score: student.assignments[homework.id],
          scoredAt: new Date(),
          studentRef: student.ref,
        },
        { merge: true }
      );
    },
    async checkScore() {
      var counter = 0;
      if (this.students != null && this.students != undefined) {
        for (var student of this.students) {
          if (student.setupped == undefined) {
            if (student.totalScore == undefined) student.totalScore = 0;
            for (var homework of this.homeworks) {
              counter++;
              student.index = counter;
              if (student.assignments == undefined) student.assignments = {};

              await homework.ref
                .collection("assignments")
                .where("studentRef", "==", student.ref)
                .get()
                .then((docs) => {
                  if (docs.empty == false) {
                    let assignment = docs.docs[0].data();
                    assignment.ref = docs.docs[0].ref;
                    assignment.id = docs.docs[0].id;
                    if (assignment.score) {
                      student.totalScore =
                        student.totalScore + Number(assignment.score);
                    }
                    student.assignments[homework.id] = assignment;
                  } else {
                    student.assignments[homework.id] = null;
                  }
                });
            }
            student.setupped = true;
          }
        }
        this.loading = false;
        console.log(this.students);
      }
    },
  },
  created() {
    this.savedScore = {};
    for (var homework of this.homeworks) {
      console.log(homework);
      this.headers2.push({
        text: homework.title,
        value: "assignments[" + homework.id + "]",
        sortable: true,
      });
    }
    this.headers2.push({
      text: "Total Score",
      value: "totalScore",
      sortable: true,
    });
    this.checkScore();
  },
  watch: {
    async students() {
      this.checkScore();
    },
  },
};
</script>
<style>
.scoreTables td,
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>